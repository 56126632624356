export const colorToHex = (color) => {
  let hex
  if (color === 'dark-blue') hex = '#154B6D'
  else if (color === 'blue') hex = '#3EA49A'
  else if (color === 'light-blue') hex = '#00AEBC'
  else if (color === 'dark-green') hex = '#29662C'
  else if (color === 'green') hex = '#84BF41'
  else if (color === 'green-uam') hex = '#32701E'
  else if (color === 'dark-yellow') hex = '#EDCE3B'
  else if (color === 'yellow') hex = '#EFA92C'
  else if (color === 'uam-yellow') hex = '#FCEE21'
  else if (color === 'light-yellow') hex = '#FFF1B0'
  else if (color === 'red') hex = '#B5272D'
  else if (color === 'orange') hex = '#E9822C'
  else if (color === 'purple') hex = '#705F9A'
  else if (color === 'brown') hex = '#5E4122'
  else if (color === 'dark-teal') hex = '#086c64'
  else if (color === 'teal') hex = '#007483'
  else if (color === 'light-gray') hex = '#F2F2F2'
  else if (color === 'gray') hex = '#E0E0E0'
  else if (color === 'dark-gray') hex = '#4f4f4f'
  else if (color === 'black') hex = '#1D252C'
  else if (color === 'white') hex = '#ffffff'
  else if (color === 'red-covid') hex = '#FD6563'

  return hex
}

export const sectionList = [
  'primeros-pasos',
  // 'first-steps',
  'configura-tu-entorno-digital',
  // 'digital-environment',
  'conoce-tu-centro-de-estudios',
  // 'studies-center',
  'averigua-quien-puede-ayudarte',
  // 'who-can-help',
  'aprovecha-los-recursos',
  // 'resources',
  'vive-la-autonoma',
  // 'live-autonoma',
  'gestiona-tu-dia-a-dia',
  // 'manage-day-by-day',
  'completa-tu-perfil-profesional',
  // 'professional-profile',
  'normativa',
  // 'norms',
  'despues-del-grado',
  // 'after-grade',
  'utilidades'
  // 'utilities',
]

export const contacts = {
  'registro-general': {
    title: 'Registro general',
    address: 'Rectorado, planta baja.',
    phone: '',
    emails: ['registro.general@uam.es'],
    web: '',
    color: 'dark-gray'
  },
  'docencia-digital-uam': {
    title: 'Docencia digital UAM',
    address: 'Edificio del Centro de Estudios de Posgrado, planta baja',
    phone: '91497 3723',
    emails: ['docencia.red@uam.es'],
    web: '',
    color: 'dark-blue'
  },
  'uam-estudiantes': {
    title: 'UAM Estudiantes',
    address: 'Edificio Plaza Mayor, planta baja',
    map: 'https://www.google.com/maps/place/UAM+Estudiantes+-+Promoci%C3%B3n+y+Atenci%C3%B3n+Integral/@40.5455175,-3.7060066,14.75z/data=!3m1!5s0xd422b054ced19f5:0x29b247cc758ccae!4m10!1m2!2m1!1sOficina+UAM+Estudiantes!3m6!1s0xd422b05523743b1:0xe48fc58f90426ae5!8m2!3d40.545101!4d-3.693271!15sChdPZmljaW5hIFVBTSBFc3R1ZGlhbnRlc1oZIhdvZmljaW5hIHVhbSBlc3R1ZGlhbnRlc5IBFXVuaXZlcnNpdHlfZGVwYXJ0bWVudJoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VONU9EUmhRMFYzRUFF4AEA!16s%2Fg%2F1ptxy168k?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D',
    phone: '91497 5015 / 4990 ',
    emails: ['atencion.estudiante@uam.es', 'asociaciones.estudiantes@uam.es', 'atencion.asuntos.academicos@uam.es', 'ayudas.participacion.estudiantil@uam.es'],
    web: 'https://www.uam.es/uam/estudiantes',
    color: 'light-blue'
  },
  'defensoria-universitaria': {
    title: 'Defensoría Universitaria',
    address: 'Edificio de Rectorado, 3ª entreplanta',
    phone: '91497 3592',
    emails: ['defensor.universitario@uam.es'],
    web: 'https://www.uam.es/uam/organos-gobierno/defensoria-universitaria',
    color: 'light-blue'
  },
  'unidad-igualdad': {
    title: 'Unidad de Igualdad de Género',
    address: 'Edif. de Rectorado, entreplanta baja',
    phone: '91497 8458',
    emails: ['unidad.igualdad@uam.es'],
    web: 'https://www.uam.es/uam/unidad-igualdad-genero',
    color: 'light-blue'
  },
  'unidad-escucha': {
    title: 'Unidad de Escucha y Acompañamiento',
    address: 'Situada en UAM Estudiantes (Edificio Plaza Mayor, planta baja)',
    phone: '692 55 1555',
    emails: ['escuchavacompanamiento@uam.es'],
    web: '',
    color: 'light-blue'
  },
  'centro-psicologia-aplicada': {
    title: 'Centro de Psicología Aplicada (CPA)',
    address: 'Pabellón C, 1ª planta',
    phone: '91497 8687',
    emails: ['cpa@uam.es'],
    web: '',
    color: 'light-blue'
  },
  'unidad-equidad': {
    title: 'Unidad de Equidad Social',
    address: 'Edif. de Rectorado, 3ª entreplanta',
    phone: '91497 4666',
    emails: ['unidad.equidad@uam.es'],
    web: '',
    color: 'light-blue'
  },
  'diversidad-funcional': {
    title: 'Atención a la Diversidad Funcional',
    address: 'Edificio Plaza Mayor, planta baja (situado en el espacio de la Oficina de Acción Solidaria y Cooperación)',
    phone: '91497 7628',
    emails: ['diversidad.funcional@uam.es'],
    web: '',
    color: 'light-blue'
  },
  'becas-y-ayudas': {
    title: 'Sección de Becas y Ayudas al Estudio',
    address: 'Edificio Plaza Mayor, planta baja',
    phone: '91497 4163 / 2697',
    emails: ['seccion.becas@uam.es'],
    web: '',
    color: 'red'
  },
  idiomas: {
    title: 'Servicio de Idiomas',
    address: 'Pabellón A, planta baja',
    phone: '91497 4633 / 5044',
    emails: ['servicio.idiomas@uam.es'],
    web: '',
    color: 'red'
  },
  'relaciones-internacionales': {
    title: 'Servicio de Relaciones Internacionales y Movilidad',
    address: 'Edificio Plaza Mayor, planta baja ',
    phone: '91497 4163 / 2697',
    emails: ['serim@uam.es'],
    web: '',
    color: 'red'
  },
  uamx: {
    title: 'Oficina UAMx',
    address: 'Pabellón de Estudios Posgrado, planta sótano',
    phone: '91497 3496 ',
    emails: ['uamx@uam.es'],
    web: '',
    color: 'red'
  },
  'educacion-fisica-y-deporte': {
    title: 'Servicio de Educación Física y Deporte',
    address: 'Pabellón Norte (junto a P. Deportes)',
    phone: '91497 3951 / 5041',
    emails: ['servicio.deportes@uam.es'],
    web: '',
    color: 'dark-green'
  },
  competiciones: {
    title: 'Competiciones',
    address: '',
    phone: '',
    emails: ['servicio.deportes.interna@uam.es', 'servicio.deportes.externa@uam.es'],
    web: '',
    color: 'dark-green'
  },
  'actividades-culturales': {
    title: 'Oficina de Actividades Culturales',
    address: 'Edificio Rectorado, 2ª entreplanta',
    phone: '91497 4359 / 4645',
    emails: ['actividades.culturales@uam.es'],
    web: '',
    color: 'dark-green'
  },
  'cultura-cientifica': {
    title: 'Unidad de Cultura Científica',
    address: 'Edif. de Rectorado, 1ª entreplanta',
    phone: '91497 6731',
    emails: ['cultura.cientifica@uam.es'],
    web: '',
    color: 'dark-green'
  },
  'accion-solidaria': {
    title: 'Oficina de Acción Solidaria y Cooperación',
    address: 'Edificio Plaza Mayor, planta baja',
    phone: '91497 7602',
    emails: ['iniciativa.solidaria@uam.es'],
    web: '',
    color: 'dark-green'
  },
  sostenibilidad: {
    title: 'Oficina de sostenibilidad',
    address: 'Edificio Plaza Mayor, 1ª planta',
    phone: '',
    emails: ['oficina.sostenibilidad@uam.es'],
    web: '',
    color: 'dark-green'
  },
  'juan-luis-vives': {
    title: 'Colegio Mayor Juan Luis Vives',
    address: 'C/ Francisco Suárez, 7 28036, Madrid',
    phone: '91497 9900',
    emails: ['cmluisvives@uam.es'],
    web: '',
    color: 'dark-green',
    map: 'https://g.page/uam_vives?share'
  },
  'residencia-erasmo': {
    title: 'Residencia Erasmo',
    address: 'C/ Erasmo de Rotterdam 5-7 28049, Madrid (Cantoblanco)',
    phone: '91131 2100',
    emails: ['erasmo@resa.es'],
    web: '',
    color: 'dark-green',
    map: 'https://goo.gl/maps/DHzKDn1ms3PXd3d68'
  },
  cauti: {
    title: 'Centro de Atención a Usuarios de Tecnologías de la Información (CAUTI)',
    address: 'E. Politécnica Superior, Edificio B, planta baja, despacho TI-107',
    phone: '91497 4029',
    emails: ['cau@uam.es'],
    web: '',
    color: 'purple'
  },
  reprografia: {
    title: 'Centro de Servicios Documentales (Reprografía)',
    address: 'Edificio Plaza Mayor, 1ª planta',
    phone: '91497 4251',
    emails: ['csd.uam@canon.es'],
    web: '',
    color: 'purple'
  },
  libreria: {
    title: 'Librería',
    address: 'Edificio Plaza Mayor, planta baja',
    phone: '91497 4994',
    emails: ['libreriauam.delsa@troa.es'],
    web: '',
    color: 'purple'
  },
  'formacion-continua': {
    title: 'Centro de Formación Continua',
    address: 'Pabellón D, primera planta',
    phone: '91497 7419 / 5538',
    emails: ['estudios.propios@uam.es'],
    web: '',
    color: 'dark-teal'
  },
  'practicas-empleabilidad': {
    title: 'Oficina de Prácticas y Empleabilidad',
    address: 'Edificio Plaza Mayor, 1ª planta',
    phone: '91497 4969 / 5056 / 4968',
    emails: ['ope.informacion@uam.es', 'ope.cursosformacion@uam.es'],
    web: '',
    color: 'dark-teal'
  },
  'uam-emprende': {
    title: 'UAM Emprende',
    address: 'Pabellón C, planta baja',
    phone: '91497 3441',
    emails: ['info@emprende.uam.es'],
    web: '',
    color: 'dark-teal'
  },
  posgrado: {
    title: 'Centro de Estudios de Posgrado',
    address: 'Pabellón D, primera planta',
    phone: '91497 5015',
    emails: ['atencion.posgrado@uam.es', 'posgrado.oficial@uam.es'],
    web: '',
    color: 'orange'
  },
  doctorado: {
    title: 'Escuela de Doctorado',
    address: 'C/ Francisco Tomás y Valiente, 2 - Pabellón D',
    phone: '',
    emails: ['doctorado.gestion@uam.es'],
    web: '',
    color: 'orange'
  },
  alumniuam: {
    title: 'Oficina AlumniUAM',
    address: 'Edificio Plaza Mayor, 1ª planta',
    phone: '91497 3939',
    emails: ['atencion.alumni@uam.es'],
    web: '',
    color: 'orange'
  },
  cibiuam: {
    title: 'CIBIUAM',
    address: 'Junto a la estación de Cercanías',
    phone: '91497 8481 / 5073 ',
    emails: ['cibiuam@uam.es'],
    web: '',
    color: 'blue'
  },
  'secretaria-virtual': {
    title: 'SIGMA',
    phone: '',
    emails: [],
    web: 'https://secretaria-virtual.uam.es',
    color: 'dark-blue',
    address: ''
  }
}

export const associationsData = [
  {
    video: 'https://www.youtube.com/embed/OIVIiNlJmFs',
    name: 'Frente de estudiantes',
    center: 'Interfacultativa',
    description: [
      'El Frente de Estudiantes es un sindicato estudiantil que tiene como objetivos construir una educación pública que sea de calidad, gratuita y esté al servicio del pueblo trabajador. Junto al resto de actores de la comunidad educativa (asociaciones de padres, profesores, sindicatos, etc) pretende hacer realidad esa educación que la sociedad demanda.',
      'En las universidades, el Frente de Estudiantes día a día da voz al estudiantado en los órganos de representación, es decir, en las juntas de facultad, departamentos, claustro, etc. De esta manera defiende los derechos de los estudiantes y lucha por alcanzar nuevos derechos para facilitar el estudio al conjunto de los estudiantes. La representación de los intereses de los estudiantes es la seña de identidad principal del Frente de Estudiantes, es la voluntad de defender al estudiantado en cualquier lugar. Como sindicato estudiantil, el Frente de Estudiantes también impulsa actividades para involucrar al estudiantado en la defensa de la educación. Estas actividades tienen como finalidad generar unidad entre los estudiantes y solidaridad. En estas actividades también se pretende ofrecer a los estudiantes una herramienta para mejorar sus condiciones de estudio. Es importante destacar actividades de tipo cultural y deportiva para que el estudiantado tenga acceso a un ocio que sea verdaderamente enriquecedor para sí mismo.',
      'El Frente de Estudiantes lucha porque las prácticas que son ofrecidas a los estudiantes sean remuneradas, para que no se ejerza un abuso desmedido sobre sus derechos, porque al fin y al cabo están realizando un trabajo. \nEn resumen, el Frente de Estudiantes pretende ser el interlocutor válido de los estudiantes en cada centro de estudio.'
    ],
    web: 'https://www.frentedeestudiantes.es/',
    join: 'https://docs.google.com/forms/d/e/1FAIpQLSfewiz3X4V1a-WyIVf-_SSfEj3CBN4tvkikqbXU-m6S8kvc-w/viewform',
    instagram: 'https://www.instagram.com/fde_estatal/',
    twitter: 'https://x.com/fde_estatal?s=08',
    facebook: 'https://www.facebook.com/frentedestudiantesfde/',
    email: ''
  },
  {
    video: 'https://www.youtube.com/embed/SNvUJv4zHWQ',
    name: 'Asociación de desarrollo de videojuegos',
    center: 'Escuela Politécnica Superior',
    description: [
      'El objetivo principal de la Asociación de Desarrollo de Videojuegos (ADV) es facilitar a los distintos alumnos de la EPS el aprendizaje o perfeccionamiento de diversas habilidades relacionadas con la programación y diseño de videojuegos.',
      'Para esto pretendemos crear un grupo de github común donde los distintos miembros puedan comenzar y compartir proyectos.',
      'La asociación también pretende facilitar a los alumnos miembros el poder juntarse en grupos para llevar proyectos de mayor alcance. Del mismo modo, planteamos como actividad dar una charla de introducción a Unity donde en el tiempo aproximado de una hora de enseñen las bases.',
      'Consideramos que la experiencia cobra una gran importancia. Por eso mismo querríamos organizar charlas por parte de profesionales del sector. Por último, nos gustaría organizar talleres donde analicemos el código de diversos juegos de éxito, ya que muchas obras de culto son de código abierto.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: ''
  },
  {
    video: 'https://www.youtube.com/embed/l28G6Hm9j5k',
    name: 'Asociación Nao - Biología marina y submarinismo',
    center: 'Facultad de Ciencias',
    description: [
      'Uola pececill@s !!!! 🐟🐠🐟🐠🐟🐠🐟🐠',
      'NAO es una asociación enfocada sobre todo a la biología marina y el submarinismo.',
      'A lo largo del curso hacemos varias actividades, como viajes, limpiezas de basura, cinefórums y jornadas de divulgación con distintos profesionales tanto de la uni como de fuera, que nos acercan el mundo marino con sus interesantes charlas (y anécdotas de batalla).',
      'Los aclamados viajes se realizan 2 veces al año, uno sobre Noviembre y otro en Mayo (si la situación sanitaria lo permite), a precios muy baratitos, en torno a 3 noches por 40€ o precios similares. En estos viajes se hacen inmersiones, snorkel, limpiezas de playa e incluso surf, pero sobre todo se va a pasarlo muy bien y es una gran oportunidad para conocer gente nueva. Only good vibes!',
      'Ser socio te permite tener preferencia en las plazas de los viajes, acceder al material de snorkel y buceo (neoprenos, gafas, tubos, etc), utilizar el local de NAO para comer, calentar comida, charlar o echar un ping pong (el local está en la primera planta de la Facultad de Biología y está muy decorado con fotos y peces, no tiene pérdida!!).',
      'El local este primer cuatrimestre va a permanecer cerrado para prevenir contagios, pero el material se podrá pedir como de costumbre, ¡nosotros nos encargamos de desinfectarlo!',
      'Otra ventaja de hacerte socio es que accedes a descuentos en los cursos de buceo oficiales que hacemos mediante el convenio con la empresa ZOEA, hasta 80 euros!!!',
      'La cuota de socio es de 7€ pero este año se queda en 5 por la reducción de actividades como el viaje.',
      'UN PULPABRAZO!!!🐙🐙'
    ],
    web: '',
    join: 'https://docs.google.com/forms/d/e/1FAIpQLScD4RayWT2hMfOTbsrlq0PM_PPYNgPO6tKOx4xRFrSzh4nLrA/viewform?usp=sf_link',
    instagram: 'https://www.instagram.com/nao.uam/',
    twitter: '',
    facebook: '',
    email: ''
  },
  {
    video: 'https://www.youtube.com/embed/w5KBg8_FlAM',
    name: 'Asociación de Filosofía Bajo Palabra',
    center: 'Facultad de Filosofía y Letras',
    description: [
      'La Asociación de Filosofía Bajo Palabra (AFBP) se constituye al amparo de la Normativa de las Asociaciones de Estudiantes de la Universidad Autónoma de Madrid y tiene como fin principal fomentar la investigación y la publicación de textos científicos en el ámbito de las humanidades a través de su órgano: la REVISTA BAJO PALABRA. Revista que además de su versión impresa cuenta con un Portal en Internet dedicado a consolidar su presencia en los medios académicos internacionales.',
      'La Asociación de Bajo Palabra Bajo Palabra (AFBP), sita en la Universidad Autónoma de Madrid, lleva años trabajando para el desarrollo y promoción de la investigación y estudio de las humanidades y ciencias sociales, especialmente de la filosofía, y por su difusión más allá del marco académico.',
      'BAJO PALABRA es una comunidad de jóvenes filósofos y humanistas que creen que otra investigación es posible, a ellos está dedicada y se debe la AFBP.'
    ],
    web: 'http://www.bajopalabra.es',
    join: '',
    instagram: '',
    twitter: 'https://x.com/RevistaFiloBP',
    facebook: '',
    email: 'bajopalabra.asociacion.filosofía@uam.es'
  },
  {
    video: '',
    name: 'Doble Hélice',
    center: 'Facultad de Ciencias',
    description: [
      'La asociación de estudiantes de ciencias Doble Hélice nace con la intención de defender los derechos de las alumnas en una de las épocas doradas del movimiento estudiantil. Desde entonces, ha buscado ser referente entre las estudiantes luchando por una universidad pública, inclusiva y multidisciplinar, incluyendo entre nuestras actividades actos reivindicativos, ponencias de divulgación científica, cinefórums, un club de lectura, asambleas abiertas o la participación e implicación en las grandes movilizaciones que acabaron con el 3+2 e hicieron frente al plan Bolonia.',
      'Comprendiendo la universidad como un espacio de intercambio cultural y científico con mucho más que ofertar que una enseñanza cada vez más inasequible y envilecida, y entendiendo la lucha por nuestros derechos como un deber, desde Doble Hélice nos organizamos para reclamar lo que es nuestro. Somos quienes llevan vuestra voz a las instituciones mediante la representación institucional, quienes te defienden cuando necesitas matrículas extraordinarias o cuando se discuten adendas, quienes luchan por tus derechos. No esperes que lo hagan por ti, ¡organízate!'
    ],
    web: '',
    join: '',
    instagram: 'https://www.instagram.com/aec_doblehelice/',
    twitter: 'https://x.com/AecDobleHelice',
    facebook: 'https://www.facebook.com/DobleHeliceUAM',
    email: 'doblehelice.asociacion.ciencias@uam.es'
  },
  {
    video: '',
    name: 'Club de rol El Concilio',
    center: 'Facultad de Ciencias',
    description: [
      '¿Quiénes somos?',
      'Somos una asociación que disfruta de los juegos de mesa y de rol de todo tipo. Nuestro objetivo principal es relajarnos entre clases, pasando buenos ratos con cualquiera al que le interese alguno de estos temas.',
      '¿Qué hacemos?',
      'En circunstancias normales nos reunimos para charlar y jugar partidas de rol o de juegos de mesa, ya sea de forma espontánea u organizando eventos más elaborados a lo largo del curso. Dadas las circunstancias de este año, hemos cambiado el formato, haciendo partidas online de rol y jugando a videojuegos.',
      '¿Dónde estamos?',
      'En el local entre los módulos 4 y 13 de la facultad de ciencias.',
      'No dudes en contactarnos tanto si te quieres unir como si tienes cualquier duda, ¡te esperamos!'
    ],
    web: '',
    join: '',
    instagram: 'https://www.instagram.com/elconciliouam/',
    twitter: 'https://x.com/Concilio_UAM',
    facebook: 'https://www.facebook.com/ConcilioUAM',
    email: 'aeelconcilio.asociacio.ciencias@uam.es'
  },
  {
    video: '',
    name: 'QED',
    center: 'Facultad de Ciencias',
    description: [
      'QED es una asociación dedicada a la divulgación de las matemáticas a nivel facultativo, fomentando la interdisciplinariedad y la ilusión por el conocimiento, haciendo accesibles conocimientos de matemática avanzada, pudiendo así despertar el interés por las matemáticas en el resto de estudiantes.',
      'Uno de sus objetivos, entre otras actividades, es la elaboración de una revista de contenido matemático destinada a todos los estudiantes de la Facultad de Ciencias. Esta revista contará con artículos, pasatiempos, acertijos, problemas, viñetas, entrevistas y reportajes y reseñas de material cultural. Contará con una edición impresa, que se repartirá entre los miembros de la facultad y un formato online, que figurará en la página web de la revista, vinculada al Departamento de Matemáticas de la Facultad. '
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'qed.asociacion.ciencias@uam.es'
  },
  {
    video: '',
    name: 'Eh! Coli',
    center: 'Facultad de Ciencias',
    description: [
      'Eh! Coli es una asociación centrada en el interés por la microbiología. Entre sus objetivos principales figuran los de promover el interés científico dentro del campus y realizar acciones de divulgación fuera de la universidad.',
      'En Eh! Coli todo es pasión por las investigaciones y descubrimientos que ayudan a mejorar el mundo desde la microbiología, así como el fomento de una cultura general basada en los hábitos saludables y seguros poniendo la atención en los microorganismos con los que convivimos en el día a día y que, en ocasiones, pueden generar problemas sanitarios si no se tratan con precaución. '
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'ehcoli.asociacion.ciencias@uam.es'
  },
  {
    video: 'https://www.youtube.com/embed/9XXNgWxGo9E',
    name: 'AEC Manuela Malasaña',
    center: 'Facultad de Ciencias',
    description: [
      'Somos una asociación política con inquietudes antifascistas, feministas y anticapitalistas de la facultad de ciencias. Defendemos la autogestión y el apoyo mutuo como formas de organización de la vida tanto social como política.',
      'Dentro de nuestras posibilidades, tratamos de poner dichas ideas en práctica en la vida universitaria, llevando a cabo iniciativas que animen a las estudiantes a movilizarse en defensa de sus derechos.',
      '¡Anímate a conocernos! Puedes contactarnos en nuestras redes sociales'
    ],
    web: '',
    join: '',
    instagram: 'https://www.instagram.com/aecmalasana/',
    twitter: 'https://x.com/AECMalasana',
    facebook: '',
    email: 'malasana.asociacion.ciencias@uam.es'
  },
  {
    video: 'https://www.youtube.com/embed/NRxHYYNFdhk',
    name: 'Sección de actividades subacuáticas (SAS UAM)',
    center: 'Interfacultativa',
    description: [
      '¿QUIÉNES SOMOS?',
      'La Sección de Actividades Subacuáticas (SAS-UAM), se creó con el objetivo general de que la comunidad universitaria encontrara un lugar donde desarrollar nuevas actividades relacionadas con el medio subacuático, tanto de conocimiento como de carácter deportivo.',
      'Con una razón: Ofrecer un espacio nuevo donde realizar proyectos tanto de investigación como de desarrollo de actividades, bien sean lúdicas, deportivas o de conocimiento...',
      'Con un objetivo: Actuar como asociación deportiva. Mantener un grupo donde compartir experiencias y realizar proyectos para un beneficio conjunto, siempre referidos al mundo sumergido.',
      'NOTA: NO ES NECESARIO SER BUCEADOR PARA PARTICIPAR EN NUESTRAS ACTIVIDADES.',
      'Nuestra filosofía:',
      'Actuamos como asociación deportiva. Nuestro objetivo no es empresarial ni lucrativo, más bien el mantener un grupo donde compartir experiencias y realizar proyectos para un beneficio conjunto.',
      'Partimos de un principio fundamental: “formamos buceadores, no vendemos cursos”.',
      '¿QUÉ HACEMOS?',
      'Viajes y excursiones y entrenamiento. Además realizamos actividades de formación orientadas a la competición, pero también conferencias, exposiciones, foros o cursos.'
    ],
    web: 'https://sasuam.wordpress.com/',
    join: '',
    instagram: '',
    twitter: '',
    facebook: 'https://www.facebook.com/SAS-UAM-529367360507728/',
    email: 'sas.uam@uam.es'
  },
  {
    video: 'https://www.youtube.com/embed/Y-iPQUVvcIE',
    name: 'Club de seguridad informática UAM',
    center: 'Escuela Politécnica Superior',
    description: [
      'SEIF es una asociación de alumnos de la EPS. Hacemos charlas sobre hacking y vulnerabilidades que han sido descubiertas, además de proyectos e investigaciones de seguridad informática. Estamos muy abiertos a la colaboración y realización de proyectos grandes como asociación, así que si estás interesado en la seguridad informática, tanto si sabes mucho como si no, eres bienvenido a contactar con nosotros.'
    ],
    web: 'https://seif.es',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'seif.asociacion.eps@uam.es'
  },
  {
    video: 'https://www.youtube.com/embed/yPVsYQmQwe0',
    name: 'UAM Rugby',
    center: 'Interfacultativa',
    description: [
      '¡Buenas a tod@s! ¿Tienes ganas de probar un deporte de espíritu universitario, dónde el compañerismo lo es todo y donde pasarlo bien?',
      '¡Ven a probar el rugby con nosotr@s! No te dejes engañar por estereotipos; da igual la altura, la anchura, el peso o el tamaño… ¡lo importante es saber moverse!',
      'En UAM Rugby promovemos un espíritu de equipo único en todo el campus, donde el compañerismo y buenrollismo lo son todo, siempre sin perder las ganas por competir y dar hasta la última gota en el campo.',
      'Aún con el año raro que nos está tocando pasar, la competición está prevista para reanudarse en enero, ¡estamos guardando tu hueco en el campo! Si nunca has probado el mejor deporte jamás inventado, vas a encontrar en el equipo una nueva familia, y, por supuesto, si ya eres un experto, estás más que bienvenido a incorporarte en nuestras filas.',
      'Te esperamos los miércoles de 13:30 a 15:30 (masculino) y los viernes de 13:30 a 15:30 (femenino). ¡No dudes en escribirnos (y seguirnos)!',
      '¡Un saludo y hasta pronto!',
      'Tu equipo, UAM Rugby.'
    ],
    web: '',
    join: '',
    instagram: 'https://www.instagram.com/uam_rugby/',
    twitter: '',
    facebook: '',
    email: 'rugby.asociacion@uam.es'
  },
  {
    video: '',
    name: 'Antares',
    center: 'Facultad de Ciencias',
    description: [
      '¡Hola, hola!',
      'Somos de Antares, la agrupación más chula de la Universidad, si no nos crees, ven a rebatirlo a nuestro local y ya de paso, quédate a comer y a charlar con nosotras (bueno, cuando no haya COVID). En el local (ahora virtual en Discord) nos reunimos para decidir qué actividades chulas hacemos, comer, estudiar y pasar el rato en familia.',
      'En cuanto a las actividades como tal, subimos al Observatorio de la UAM con nuestros telescopios (te enseñamos a usarlos, ¡sin miedo!) y un par de veces al año nos vamos de acampada. Cogemos todos nuestros bártulos y los llevamos de paseo (¡pi, pi, pi!) a un pueblo de Toledo donde pasamos la noche mirando al cielo, fotografiándolo y quizás bebiendo chocolate caliente (ni confirmamos ni desmentimos). También nos gusta dar charlas divulgativas tanto en la uni como en coles y reunirnos virtualmente para ver pelis de estrellitas.',
      'Si te gusta la astronomía o simplemente tienes curiosidad por saber cómo se ve a través de un telescopio, ¡siéntete bienvenida! ¡Mándanos un correo y te recibiremos con los telescopios abiertos! Te invitamos también a que cotillees nuestra página web, nuestro x.com y nuestro Instagram.',
      '¡Anímate, que no mordemos, y esperemos tener cielos despejados! ;)'
    ],
    web: 'http://agrupacionantares.weebly.com',
    join: '',
    instagram: 'https://www.instagram.com/antares_uam/?hl=es',
    twitter: 'https://x.com/AntaresUAM',
    facebook: '',
    email: 'antares.asociacion.ciencias@uam.es'
  },
  {
    video: 'https://www.youtube.com/embed/IOouS2qSpxw',
    name: 'SEO Monticola',
    center: 'Facultad de Ciencias',
    description: [
      'El Grupo Ornitológico SEO-Monticola es una asociación de estudiantes de la UAM, además de un grupo local de SEO/BirdLife y un grupo de anillamiento científico de aves.',
      'Realizamos todo tipo de actividades centradas en la divulgación y conservación de la naturaleza y de las aves en concreto: excursiones, cursos, talleres, charlas, cinefórums, anillamiento científico...',
      'Si te gusta ir al campo y te gustan las aves, ¡anímate a participar! Búscanos en el local de asociaciones II en la planta baja del Edificio de Biología, en redes sociales o en nuestra página web.'
    ],
    web: 'https://seomonticola.org/',
    join: '',
    instagram: 'https://www.instagram.com/seomonticola/?hl=es',
    twitter: 'https://x.com/SEOMonticola',
    facebook: '',
    email: 'seomonticola.asociacion.ciencias@uam.es'
  },
  {
    video: 'https://www.youtube.com/embed/SfczMIKUFwY',
    name: 'Asociación Japón',
    center: 'Facultad de Filosofía y Letras',
    description: [
      'Hola a todos, somos la Asociación Japón, una asociación estudiantil sin ánimo de lucro dirigida a estudiantes y no estudiantes que fue fundada en 2014 con el propósito de acercar la cultura japonesa a España. Para ello llevamos a cabo diferentes actividades, además de promover las relaciones entre España y Japón transmitiendo información útil para aquellos que estén interesados en descubrir las maravillas de Japón, vivir o estudiar allí. En nuestra página web subimos artículos acerca de cualquier tema relacionado con el país nipón, de arte, cultura, historia, tradiciones, etc. También organizamos conferencias relacionadas con la cultura japonesa con la colaboración de especialistas, profesores, investigadores, entre otros. Además de artículos, en nuestra página web podéis encontrar podcasts, donde hablaremos de forma más casual sobre varios temas de Japón y tenemos canal de YouTube donde podréis encontrar las conferencias a las que no hayáis podido asistir. No sólo tenemos página web, estamos muy activos por nuestras redes sociales las cuales son muy útiles para enterarse de nuestras últimas novedades:  Instagram, Twitter y Facebook. En las redes sociales compartimos posts tanto didácticos (apps para aprender el idioma, vocabulario...), hasta posts relacionados con su cultura, noticias y novedades en Japón entre otras muchas cosas más. Esto es una mínima parte de lo que tenemos por ofrecer, así que si queréis conocernos más ¡ya sabéis que hacer!'
    ],
    web: '',
    join: '',
    instagram: 'https://www.instagram.com/asociacionjapon/?igshid=87cw6hdhxlgx',
    twitter: 'https://x.com/asociacionjapon?s=21',
    facebook: 'https://www.facebook.com/asociacionjapon/',
    email: 'asociacionjapon.filosofia@uam.es'
  },
  {
    video: '',
    name: 'Asociación La Jerónima',
    center: 'Facultad de Filosofía y Letras',
    description: [
      'La Jerónima es una asociación dedicada al interés de la traducción e interpretación, cuyos miembros suelen ser estudiantes de titulaciones relacionadas.',
      'La asociación organiza actividades relacionadas con este ámbito, desde conferencias, seminarios, cursos o debates, hasta visitas o concursos con fines pedagógicos. Además, se interesa especialmente por ayudar a los estudiantes del Grado en Traducción e Interpretación, buscando mejorar su preparación y salidas profesionales, así como aportando nuevos recursos.  También mantiene vinculación con la Asocaición Española de Traductores e Intérpretes en formación (AETI).'
    ],
    web: 'https://lajeronima.wordpress.com/',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'lajeronima.asociacion.filosofia@uam.es'
  },
  {
    video: '',
    name: 'Asociación de Tunas',
    center: 'Facultad de Medicina',
    description: [
      'La asociación promueve la cultura musical en la universidad.',
      'Entre sus objetivos está el de promocionar una cultura musical propia, fomentar la canción popular española y extenderla a nivel nacional e internacional, así como interrelacionar la cultura universitaria con otros países o mostrar los hábitos culturales universitarios a otras culturas.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'tuna.asociacion.medicina@uam.es'
  },
  {
    video: '',
    name: 'No damos crédito',
    center: 'Facultad de Medicina',
    description: [
      'No Damos Crédito (NDC) es una asociación de teatro amateur formado por personas de distintas edades, desde alumnos recién entrados en la facultad, hasta médicos residentes y adjuntos. La mayoría son estudiantes de los distintos cursos de Medicina y Enfermería, pero también hay miembros de otras facultades y antiguos alumnos.',
      'El grupo siempre ha estado abierto a nuevas incorporaciones, lo que ha permitido que la asociación crezca extraordinariamente en pocos años y represente varias obras anualmente en la Facultad de Medicina de la Universidad Autónoma de Madrid.',
      'A lo largo de sus más de veinte años de vida, el grupo se ha atrevido con grandes clásicos de la literatura, del cine, de los musicales e incluso de Disney, pero siempre aportando un poco de cada uno de sus miembros, de ese sentimiento que es NDC.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'ndc.asociacion.medicina@uam.es'
  },
  {
    video: 'https://www.youtube.com/embed/NrXsnzzwCww',
    name: 'Asociación Internacional de Estudiantes de Medicina y Enfermería de la Autónoma (AIEME UAM)',
    center: 'Facultad de Medicina',
    description: [
      'AIEME-UAM (Asociación Internacional de Estudiantes de Medicina y Enfermería de la Autónoma), con sede en la facultad de Medicina del Hospital La Paz, tiene como objetivo principal incrementar la formación tanto humana como médica de los estudiantes de Ciencias de la Salud. Con nuestro lema “no puedes cambiar el mundo, pero sí tu micromundo” queremos hacer llegar a los estudiantes una educación adicional a la que se enseña en las aulas que les permita formarse mejor como ciudadanos y personas. Sin embargo, no solo se limita a estos, ya que tenemos Grupos de Trabajo que complementan los conocimientos y habilidades que recibimos en clase. Estos Grupos de Trabajo tratan temas como Derechos Humanos y Paz, Educación Médica, Salud Pública, Salud sexual/sexualidad, VIH, Sida y otras ITS. Ofrecemos intercambios internacionales, tanto clínicos como de investigación, a estudiantes de medicina. Somos parte de una federación internacional y, a nivel nacional, se ofrecen trainings de formación tan diversos como: comunicación, liderazgo, resolución de conflictos o trabajo en equipo.',
      'Nuestras actividades:',
      'BREAKING THE SILENCE: curso de lengua de signos. Todos los años se abren una o dos convocatorias (octubre y/o marzo) para enseñar a cualquier persona que quiera sobre la lengua de signos española en vídeos y de forma presencial. Una de las actividades más prolíficas de la asociación y que tiene fama nacional. ¡Tenemos una página de Facebook que podéis mirar!',
      'Un sugus, una sonrisa: este año no ha podido realizarse por las restricciones a las reuniones en espacios públicos, pero consiste en una recaudación de fondos vendiendo sugus por el precio simbólico de 1 euro en puntos varios de las facultades. ¿Para qué sirve ese dinero? ¡¡¡¡REGALOS DE NAVIDAD!!!! Compramos regalos para los niños de familias en riesgo de exclusión social y que al menos unos pocos niños puedan disfrutar de una navidad divertida con regalos.',
      'Hospital de ositos: en junio invitamos a colegios públicos con niños de barrios o distritos más desfavorecidos a pasar la mañana en la facultad de Medicina para enseñarles hábitos de vida saludables y a perder el miedo a las visitas a su pediatra y enfermera. Esta actividad está más enfocada a la participación de estudiantes de medicina, enfermería y nutrición al desarrollarse en nuestra facultad, pero todos pueden apuntarse siempre y cuando no os importe tener muchos, muchos niños a vuestro alrededor :P',
      'Charlas de todas las temáticas que se nos ocurran a todos relacioandas con la salud, mujeres, transexualidad, prostitución, etc. –',
      'Intercambios: la actividad estrella de la asociación. Por desgracia, se tratan de intercambios clínicos enfocados a pasar un mes de prácticas en un hospital extranjero, por lo que se requieren conocimientos de medicina para poder participar. Existen más intercambios relacionados con salud pública y salud sexual, así que si estuvieseis interesados podéis poneros en contacto con nosotros para intentar moveros en uno de ellos.'
    ],
    web: '',
    join: '',
    instagram: 'https://www.instagram.com/aiemeuam/?hl=es',
    twitter: 'https://x.com/AIEMEUAM',
    facebook: '',
    email: 'aieme.asociacion.medicina@uam.es'
  },
  {
    video: '',
    name: 'Philobiblion',
    center: 'Facultad de Filosofía y Letras',
    description: [
      'En Philobiblion: Asociación de Jóvenes Hispanistas nos dedicamos a la difusión y promoción de la investigación en el ámbito del hispanismo en todas sus disciplinas: literatura, lingüística, cine, pensamiento, pintura, música, etc. La asociación está conformada fundamentalmente por estudiantes de grado y posgrado de distintas instituciones, aunque radicados en su mayor parte en la Universidad Autónoma de Madrid, y aspira a constituir una plataforma para el intercambio de ideas y, sobre todo, para que los asociados pongan en marcha cualquier proyecto de interés cultural. Realizamos diferentes actividades entre las que se encuentran:',
      '* Un congreso internacional anual.',
      '* Seminarios de investigación, conferencias, charlas, etc.',
      '* La publicación periódica de Philobiblion: revista de literaturas hispánicas.',
      '* Publicación de volúmenes colectivos y libros.',
      '* Promoción de la investigación.',
      '* Actividades culturales dentro y fuera de la universidad.'
    ],
    web: 'http://www.joveneshispanistas.es',
    join: '',
    instagram: 'https://www.instagram.com/philobiblionuam/?hl=es',
    twitter: 'https://x.com/PhilobiblionUAM',
    facebook: 'https://www.facebook.com/philobiblion.ajhuam',
    email: 'philobiblion.asociacion.filosofia@uam.es'
  },
  {
    video: 'https://www.youtube.com/embed/7IYwZGQ8gZg',
    name: 'Grupos Bíblicos UAM',
    center: 'Interfacultativa',
    description: [
      'La asociación GBUAM surge a partir de la idea de estudiantes universitarios para conformar un grupo donde a través de charlas amenas, estudios bíblicos que desmitifiquen ciertos estereotipos y percepciones sociales, así como actividades relacionadas con valores humanos, se aporte un sentido crítico y reflexivo a otros integrantes de la comunidad universitaria y, en consecuencia, a la propia actividad y oferta cultural de la universidad.',
      'Actividades como charlas y debates, excursiones a distintas localidades de Madrid, ciertas conferencias esporádicas, nuestros “musicfórums” y “videofórums”, fiestas temáticas y encuentros universitarios han contribuido a crear el clima de cordialidad y buen trato existente entre los miembros de la asociación.',
      'Hoy por hoy, los miembros del GBUAM compaginamos nuestros estudios universitarios con la realización de todas estas actividades, así como con la elaboración de nuevas ideas y proyectos; para lo cual nos reunimos al menos una vez por semana en nuestro local en la Isla C de la Plaza Mayor en el campus de Cantoblanco.',
      'No obstante, a partir del mes de marzo, dada la excepcionalidad provocada por la situación de pandemia, hemos optado por las reuniones de manera virtual, a través de la plataforma de zoom.',
      'Para más información, puedes contactar con nosotros mediante nuestro Instagram.'
    ],
    web: '',
    join: '',
    instagram: 'https://www.instagram.com/gbumadrid/?hl=es',
    twitter: '',
    facebook: '',
    email: 'gruposbiblicos.asociacion@uam.es'
  },
  {
    video: '',
    name: 'Club Alpino',
    center: 'Facultad de Ciencias',
    description: [
      'En nuestra asociación nos reunimos estudiantes y miembros de la comunidad universitaria que sentimos curiosidad y pasión por el mundo de las montañas. Nos acercamos a las mismas por medio de actividades encaminadas a conocerlas, comprenderlas y respetarlas. Realizamos excursiones, charlas, talleres, exposiciones y proyecciones, donde la montaña es siempre la protagonista.',
      'Nos puedes encontrar en el local situado en el Módulo 8 de la Facultad de Ciencias de la UAM, en la entreplanta primera.'
    ],
    web: 'http://clubalpinociencias.blogspot.com/',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'clubalpino.asociacion.ciencias@uam.es'
  },
  {
    video: '',
    name: 'Malayerba',
    center: 'Facultad de Ciencias',
    description: [
      'Asociación dedicada a la reflexión y debate sobre temas de muy variado alcance, tanto a nivel local (universitario) como global (difusión de problemáticas de grupos desfavorecidos de cualquier cultura, seguimiento de líneas de acción intercontinentales en contra de la globalización y el imperialismo).',
      'Datos de contacto: Local situado en el pasillo principal de la 1ª planta del Edificio de Biología enfrente del peine A. C/ Darwin, 2 Facultad de Ciencias -Universidad Autónoma de Madrid Campus de Cantoblanco- C/ Francisco Tomás y Valiente, 7, 28049, (Madrid).'
    ],
    web: 'http://malayerbanunkamuere.blogspot.com/',
    join: '',
    instagram: '',
    twitter: 'https://x.com/aemalayerba?lang=es',
    facebook: '',
    email: 'malayerba.asociacion.ciencias@uam.es'
  },
  {
    video: '',
    name: 'Vivero y Huerto',
    center: 'Facultad de Ciencias',
    description: [
      'La Asociación tiene como finalidad practicar, promover y difundir los principios de la agricultura biológica, sus métodos y sus implicaciones ambientales.'
    ],
    web: 'https://sites.google.com/site/viveroyhuertouam/',
    join: '',
    instagram: '',
    twitter: '',
    facebook: 'https://www.facebook.com/huertouam/?fref=ts',
    email: 'viveroyhuerto.asociacion.ciencias@uam.es'
  },
  {
    video: '',
    name: 'Habeas Corpus',
    center: 'Facultad de Derecho',
    description: ['Asociación de estudiantes de la Facultad de Derecho de la UAM, que lucha por una educación pública y de calidad, feminista e inclusiva.'],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'habeascorpus.asociacion.derecho@uam.es'
  },
  {
    video: '',
    name: 'UAMIMUN',
    center: 'Facultad de Derecho',
    description: [
      'Es una asociación abierta a todos los estudiantes de la UAM, donde la participación en las distintas actividades es voluntaria y que no cobra ninguna cuota a sus socios. Los miembros de UAMIMUN representan a la Universidad en las conferencias nacionales e internacionales a las que manda delegación y pueden participar en todas las actividades que organiza la asociación.',
      'No obstante, quizás lo más importante de formar parte de UAMIMUN es que en esta asociación encontrarás un grupo de gente que comparte al 100% tu interés por la política internacional, y con quien probablemente entables algunas de las mejores amistades que tengas en la universidad. Nos gusta hablar de nosotros como una pequeña gran familia en la que todos nos conocemos, y siempre estamos abiertos a ampliarnos. ¿A qué esperas?'
    ],
    web: '',
    join: '',
    instagram: 'https://www.instagram.com/uamimun/',
    twitter: '',
    facebook: '',
    email: 'uamimun.asociacion.derecho@uam.es'
  },
  {
    video: '',
    name: 'Asoc. de estudiantes de económicas (AEE)',
    center: 'Facultad de Ciencias Económicas y Empresariales',
    description: [
      'La AEE es una asociación con más de 20 años de actividad en la Facultad de Ciencias Económicas y Empresariales UAM, por cuyos estudiantes realiza su actividad protegiendo sus intereses y buscando mejorar sus recursos y problemáticas. La asociación realizar diversas actividades, entre las que destaca la edición de la revista “Fuera Dogmas” sobre divulgación económica, científica, social y cultural en el ámbito universitario.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'aee.asociacion.economicas@uam.es'
  },
  {
    video: '',
    name: 'Filosofía, Política y Economía',
    center: 'Facultad de Ciencias Económicas y Empresariales',
    description: [
      'Se trata de una asociación promovida por los estudiantes del Grado en Filosofía, Política y Economía, impartido por las universidades de la Alianza 4U, y que tiene por objetivo la mejora de sus estudios y de las perspectivas laborales de sus titulados, así como de organizar una comunidad alumni del Grado y, en definitiva, mediar entre los estudiantes del Grado y las instituciones universitarias.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'aefpe.asociacion.economicas@uam.es'
  },
  {
    video: '',
    name: 'Club de Música de Económicas',
    center: 'Facultad de Ciencias Económicas y Empresariales',
    description: [
      'El Club de Música de la Facultad de Económicas es una asociación de carácter cultural que busca reunir a todos los estudiantes de la universidad con intereses musicales, así como desarrollar actividades culturales y que permitan poner en contacto a estudiantes de diferentes grados con un mismo interés por la música.',
      'En el club se realizan ensayos y se organizan eventos donde la música es el motivo principal.  '
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'acme.asociacion.economicas@uam.es'
  },
  {
    video: '',
    name: 'Asociación de Gestión Aeronáutica',
    center: 'Facultad de Ciencias Económicas y Empresariales',
    description: ['La Asociación pretende acercar el sector aéreo a los estudiantes y que los gestores aeronáuticos sean un referente en su sector.'],
    web: 'http://www.gestionaeronautica.org/',
    join: '',
    instagram: 'https://www.instagram.com/gestion_aeronautica/',
    twitter: '',
    facebook: '',
    email: 'aega.asociacion.economicas@uam.es'
  },
  {
    video: 'https://www.youtube.com/embed/HvxbHsTGdA4',
    name: 'Asociación Pensamiento y Cultura de la Antigüedad',
    center: 'Facultad de Filosofía y Letras',
    description: [
      'La APCA somos un grupo de estudiantes universitarios que reúne a gente de grado, máster y doctorado con un interés común en la Antigüedad. Nuestra asociación será una oportunidad excelente para aprovechar e incentivar tus inquietudes investigadoras entre nosotros, un espacio para compartir opiniones, debatir e intercambiar ideas en una panorámica multidisciplinar en la que compartir tu formación y completarla, teniendo a tu lado siempre a una serie de personas dispuestas a ayudarte en todo lo que necesites. Provenimos de áreas tan diversas como Historia, Arqueología, Filología, Arte, Filosofía, Antropología, e incluso lenguas modernas. Por ello, siempre encontrarás un enfoque novedoso, distinto y enriquecedor.',
      '¿Qué hacemos? Te ofrecemos una actividad tan variada como jornadas de juegos de mesa basados en la Antigüedad, ciclos de conferencias como nuestras Jornadas sobre el Mundo Antiguo, en las que hemos colaborado en esta última edición con la Sociedad Española de Estudios Clásicos; también talleres de mitología, pintura y de juegos antiguos, torneos, concursos, salidas culturales, ¡e incluso grandes banquetes! Aunque nuestra principal actividad es divertirnos y pasar un buen rato. Sin embargo, se puede responder a “¿Qué hacemos?” de forma muy resumida: lo que tú nos pidas que hagamos. Si tienes una idea y es viable, nosotros te facilitaremos la ayuda para llevarla a cabo. Como habrás observado, nos dedicamos al otium y al studium a partes iguales. Nos ayudamos entre nosotros y colaboramos para sacar adelante nuestros proyectos Constituir parte de una asociación de estudiantes facilita algunas cosas y nos permite acceder a ciertos recursos de la facultad. Para ser miembro no te requerimos ni cuota de entrada, ni de permanencia, ni requisitos. Más fácil imposible. Ponte en contacto con nosotros y facilítanos tus datos. ¡No hay truco!'
    ],
    web: 'https://apccuam.wordpress.com/',
    join: '',
    instagram: ' https://www.instagram.com/apcauam/?hl=es',
    twitter: 'https://x.com/apcauam',
    facebook: ' https://www.facebook.com/apca.uam/',
    // email: 'apca.filosofia@uam.es',
    email: 'apca.asociacion.filosofia@uam.es'
  },
  {
    video: '',
    name: 'Cultura Crítica',
    center: 'Facultad de Filosofía y Letras',
    description: [
      'Somos una asociación de estudiantes de la Facultad de Filosofía y Letras de la UAM. Funcionamos de forma asamblearia y participativa, tratando de dinamizar culturalmente la universidad desde el anticapitalismo, el feminismo y el ecologismo.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'culturacritica.asociacion.filosofia@uam.es'
  },
  {
    video: 'https://www.youtube.com/embed/qlXu8AV50DE',
    name: 'Erasmus Student Network',
    center: 'Interfacultativa',
    description: [
      'La asociación universitaria ESN UAM fue creada en el año 1995 por varios estudiantes de la Universidad Autónoma de Madrid con el interés común de ayudar desinteresadamente a los alumnos extranjeros que venían a estudiar a nuestra universidad.',
      'Dirección: Calle Einstein 7. Edificio Plaza Mayor UAM Local 161 Isla B 1ª Planta Universidad Autónoma de Madrid, 28049 Madrid.'
    ],
    web: 'https://www.esnuam.org/',
    join: '',
    instagram: 'https://www.instagram.com/esnuam/',
    twitter: 'https://x.com/ESNUAM',
    facebook: 'https://www.facebook.com/ESN.UAM',
    email: 'esn.asociacion@uam.es'
  },
  {
    video: '',
    name: 'Wallada',
    center: 'Facultad de Filosofía y Letras',
    description: [
      'Wallada bint al-Mustakfi, poetisa andalusí del califato de Córdoba, da nombre a esta asociación, que surge del grado de Estudios de Asia y África y tiene como voluntad principal crear espacios comunes para compartir y promover la cultura arabo-islámica. Entre sus actividades destacan el teatro árabe, cafés arabófonos, proyecciones, club de debate (participando a nivel internacional), talleres y conferencias de diversa índole. Se trata de una asociación horizontal que busca expandirse con nuevas ideas y miembros. Como Wallada, sus miembros buscan tomar un papel activo en la sociedad y florecer junto al legado árabe en el espacio universitario.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'wallada.asociacion.filosofia@uam.es'
  },
  {
    video: '',
    name: 'Asociación Arqueología Activa',
    center: 'Facultad de Filosofía y Letras',
    description: [
      'La Asociación Arqueología Activa nace como iniciativa de un grupo de jóvenes estudiantes de la UAM que comparte pasión, inquietudes y formación en diferentes áreas arqueológicas.',
      'Su objetivo fundamental es focalizar los intereses y conocimientos de aquellos interesados en la materia a través de la organización de actividades complementarias a sus estudios de Grado, como pueden ser ciclos de conferencias, cursos, seminarios...',
      'La esencia de esta iniciativa se materializa en su estructura orgánica, dividida en secciones temáticas que buscan abarcar, siempre desde una mirada arqueológica, los principales periodos históricos, técnicas o metodologías de intervención.',
      'La cooperación interdisciplinar está presente en la actividad de la Asociación, permitiendo a todos sus socios completar su formación y ampliar su perspectiva investigadora, siendo de gran ayuda en su futuro laboral como arqueólogos o simplemente como apasionados de la materia.'
    ],
    web: '',
    join: '',
    instagram: 'https://www.instagram.com/arqueologiactivauam/',
    twitter: 'https://x.com/AAA_UAM?ref_src=twsrc%5Etfw&ref_url=https%3A%2F%2F',
    facebook: 'https://www.facebook.com/Asociación-Arqueolog%C3%ADa-Activa-174185643211305',
    email: ''
  },
  {
    video: '',
    name: 'Psicoart',
    center: 'Facultad de Psicología',
    description: [
      'Asociación de la Facultad de Psicología que nace con el propósito de facilitar un desarrollo cultural y artístico de sus miembros. Nos preocupamos en cultivarnos en los diversos ámbitos culturales como la literatura, poesía, pintura, caricatura, ámbitos audiovisuales, etc.',
      'Organizamos talleres de escritura y dibujo, citas para comentar obras literarias, proyección de películas y cualquier otra actividad que resulte del interés de los miembros.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'psicoart.asociacion.psicologia@uam.es'
  },
  {
    video: '',
    name: 'Asociación de Estudiantes Noam Chomsky',
    center: 'Facultad de Psicología',
    description: [
      'Asociación de Estudiantes de la Facultad de Psicología de la UAM que busca hacer realidad los proyectos y actividades de intervención socio-educativa y cultural, que propongan l@s estudiantes en base a sus propias inquietudes y en el marco de los movimientos sociales.',
      'Mediante la solidaridad y el apoyo mutuo, a través de actos, actividades y acciones de sensibilización que programamos, siempre desde la horizontalidad de la asamblea, sin jerarquías y con transparencia, luchamos por diferentes objetivos, para lo cual contamos además con representación en Junta de Centro y Claustro universitario.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'noamchomsky.asociacion.psicologia@uam.es'
  },
  {
    video: '',
    name: 'Asociación de Estudiantes ASPA',
    center: 'Facultad de Psicología',
    description: [
      'Asociación con sede en la Facultad de Psicología de la UAM dedicada al ocio universitario, reuniones de entretenimiento en comunidad y préstamo de juegos a los estudiantes.',
      'Organizamos partidas de rol, nos preocupamos de dar alternativas de ocio y nos asesoramos en temas relativos a nuestra carrera.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'aspa.asociacion.psicologia@uam.es'
  },
  {
    video: '',
    name: 'Asociación Universitaria de Amigos de la Cultura y el Ocio (AUACO)',
    center: 'Facultad de Medicina',
    description: [
      'La Asociación "AUACO" de la Facultad de Medicina organiza clases de baile gratuitas (Salsa, Bachata, Rock & Roll, Tango, Vals, etc.) en la propia Facultad.',
      'Además, organiza otro tipo de actividades culturales fuera de la Facultad como salidas a bailar, asistencia a obras de teatro y musicales, visitas a museos y exposiciones, excursiones, etc.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'auaco.asociacion.medicina@uam.es'
  },
  {
    video: '',
    name: 'Club de Robótica y Mecatrónica (CRM)',
    center: 'Interfacultativa',
    description: [
      'Somos una asociación de estudiantes dedicados a fomentar el interés por la robótica en la Universidad Autónoma de Madrid y España en general. Periódicamente organizamos talleres, competiciones y seminarios orientados a la difusión de la electrónica, la mecánica y el software. También proporcionamos ayuda (técnica y económica) a los estudiantes que buscan realizar proyectos relacionados con la robótica.'
    ],
    web: 'https://crm-uam.github.io/',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: ''
  },
  {
    video: '',
    name: 'AEPUMA',
    center: 'Interfacultativa',
    description: [
      'La Asociación AEPUMA (Asociación de Estudiantes del Programa Universidad para Mayores de la Universidad Autónoma de Madrid), comenzó su andadura y fue reconocida por la Autónoma en marzo de 2007.',
      'Entre otros, la labor de AEPUMA es la de canalizar las expectativas de todos los estudiantes mayores, colaborar con las autoridades académicas en el mejor funcionamiento de la Universidad de la Experiencia, poner su voz al servicio de mejorar los espacios destinados a la formación continua y tratar de potenciar las relaciones intergeneracionales.'
    ],
    web: 'https://aepuma.org/',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: ''
  },
  {
    video: '',
    name: 'Cultura China de la UAM',
    center: 'Interfacultativa',
    description: [
      'La asociación de estudiantes chinos de la UAM, fundada en 2015, tiene como objetivo principal estrechar los lazos de los estudiantes de la UAM, fomentando, difundiendo y promocionando nuestra cultura con más de 5.000 años de historia.',
      'No importa de dónde eres, de dónde vienes, si te gusta nuestra cultura. Únete y empecemos a disfrutar y prolongar esta cultura milenaria y ancestral.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: 'https://es-es.facebook.com/culturachinauam/',
    email: 'cultura.china.asociacion@uam.es'
  },
  {
    video: 'https://www.youtube.com/embed/6ig67JVAGgo',
    name: 'Asociación de estudiantes Encuentros Universidad y Cultura (EUC)',
    center: 'Interfacultativa',
    description: [
      'Una asociación para vivir plenamente el espíritu universitario de búsqueda de la verdad en todos los campos sobre todo sobre el sentido del hombre. Para ello se realizan muchas actividades de voluntariado en residencias, comedores y con niños. Video forums, charlas, excursiones a la montaña, ciclos de conferencias, clases de oratoria y técnicas de estudio, y Encuentros de Universitarios a nivel nacional en distintas ciudades de España.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'euc.asociacion.ciencias@uam.es'
  },
  {
    video: '',
    name: 'Atlántida UAM',
    center: 'Interfacultativa',
    description: [
      'Atlántida busca crear un espacio de diálogo en la UAM, con el objetivo de vivir la universidad como un lugar de encuentro y convivencia entre personas libres y creativas, dispuestas a poner en común ideas, preguntas y formas diferentes de entender la vida universitaria y el estudio. Las actividades organizadas por Atlántida tienen un marcado carácter social, entre ellas pueden destacarse las quedadas de estudio o comida en la universidad, los encuentros en Madrid, convivencias, viajes o conciertos, entre otras actividades culturales, o las acciones caritativas o voluntariados, por ejemplo. En Atlántida todo el estudiantado de la UAM será bienvenido.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'atlantida.asociacion@uam.es'
  },
  {
    video: '',
    name: 'Escritores Universitarios',
    center: 'Interfacultativa',
    description: [
      'Esta asociación está centrada en la promoción de la escritura y la literatura dentro y fuera de la vida universitaria. Entre sus actividades figuran la organización de tertulias, ponencias, micros abiertos o cualquier acto de interés y relación con la escritura. Además, se ofrece formación complementaria en el ámbito de la asociación. Si te gusta escribir o tienes un interés claro en la literatura, ¡esta es tu asociación!'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'escritores.universitarios.asociacion@uam.es'
  },
  {
    video: '',
    name: 'Libertad sin Ira',
    center: 'Interfacultativa',
    description: [
      'Libertad sin Ira colabora con los órganos directivos de la facultad y la universidad para que tanto las normas de convivencia como la libertad de expresión sean respetadas y garantizadas dentro del campus. Para ello, entre sus fines está el objetivo de confrontar cualquier corriente totalitaria, colaborar para que se garantice que cualquier tipo de manifestación política o ideológica sea pacífica, servir de ejemplo al resto de estudiantes para que no tengan miedo a expresar sus ideas libremente, organizar eventos en el campus que promuevan la cultura política universitaria, fomentar el intercambio de pensamiento con estudiantes de otras facultades y universidades, así como fomentar la hermandad con estudiantes extranjeros, especialmente iberoamericanos.',
      'Entre sus actividades habrá foros de debate, conferencias con personajes de relevancia pública en los ámbitos político, empresarial o jurídico, así como jornadas de convivencia o actividades de información.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'libertadsinira.asociacion@uam.es'
  },
  {
    video: '',
    name: 'Lumen Club de Cine',
    center: 'Interfacultativa',
    description: [
      'Este es el club para quienes amen el cine. Su objetivo es crear un espacio dedicado para que los estudiantes compartan y exploren su pasión cinéfila, un lugar donde discutir y analizar películas, intercambiar ideas o conocimientos, y fomentar la apreciación del séptimo arte.',
      'Se trata de una iniciativa que pretende, a su vez, promover la diversidad cinematográfica, organizando proyecciones de películas de diferentes géneros, épocas y países, permitiendo desarrollar una visión más amplia de la industria cinematográfica y enriquecer la perspectiva cultural de sus miembros.',
      'Otro aspecto importante de nuestro club de cine es su capacidad para fomentar la comunidad y el compañerismo entre los estudiantes. Al compartir la pasión por el cine, los miembros del club tendrían la oportunidad de conectarse entre sí, formar amistades y establecer redes de apoyo.',
      'En Lumen se organizarán salidas al cine o festivales de cine, además de trabajar en la organización de actividades centradas en éste, incluso con una dimensión formativa, y poder contar con la presencia de profesionales, expertos, actores o directores para conocer de primera mano sus experiencias y la visión más personal de su trabajo.  '
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'lumen.asociacion@uam.es'
  },
  {
    video: '',
    name: 'Techdevs UAM',
    center: 'Interfacultativa',
    description: [
      'Nuestra asociación se centra en acercar el mundo de la tecnología, la programación y la ciencia a todo el estudiantado de la unviersidad. El objetivo es contribuir a reducir la brecha entre la teoría y la práctica en este ámbito, creando un entorno en el que los estudiantes se sientan libres de experimentar y aplicar sus habilidades al uso de la tecnología, pudiendo crear soluciones a problemas del mundo real. La asociación llevará a cabo talleres, charlas individuales y eventos a lo largo del curso, contando con el apoyo de expertos en gran variedad de los ámbitos STEM, además de la comunidad global de clubes universitarios impulsada por Google Developers.',
      'El carácter interfacultativo de esta asociación busca generar un ambiente multidisciplinar en el que se analicen puntos de vista diferentes, contribuyendo al enriquecimiento colectivo.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'techdevs.asociacion@uam.es'
  },
  {
    video: '',
    name: 'Victoria Kent',
    center: 'Interfacultativa',
    description: [
      'Victoria Kent es una asociación fundada para promover y defender los derechos humanos en el desarrollo de valores que siempre los respalden.',
      'Los miembros de esta asociación basan su actividad en el debate y la reflexión crítica en el ámbito educativo, con el objetivo de difundir y acercar diferentes perspectivas con respecto a temáticas que suscitan interés académico.',
      'También buscan defender los intereses del estudiantado y promover el asociacionismo universitario en la búsqueda de mejoras para los intereses del alumnado.',
      'Entre sus actividades podrás participar en charlas y debates de diferente índole, jornadas culturales o formativas de todo tipo, con una gran vocación participativa en el apoyo al alumnado en la comunidad universitaria. '
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'victoriakent.asociacion@uam.es'
  },
  {
    video: 'https://www.youtube.com/embed/uLP3HhyM4xU',
    name: 'SOCIEDAD DE DEBATES UAM',
    center: 'Interfacultativa',
    description: [
      'La Sociedad de Debates de la UAM(SDUAM) es una asociación de estudiantes que agrupa a personas de distintas facultades y por tanto, muy diversa.Ha formado a Campeones de Debate Nacionales y de reconocido prestigio a nivel internacional, así como Subcampeones del Mundiales durante cinco años.',
      'Sin embargo, todo el mundo empieza de cero y es por eso por lo que creemos que todo el mundo puede trabajar habilidades de oratoria y debate, siendo útil para el futuro laboral pero también para el desarrollo personal y universitario.',
      'No sólo somos una asociación, sino que somos una familia muy unida a la que le gusta de vez en cuando(mejor dicho, muy de vez en cuando), debatir en cualquier universidad, de cualquier parte de España e incluso el mundo.',
      'Si quieres unirte, o quieres saber un poco mejor a qué nos dedicamos en detalle, no dudes en contactar con nosotros.'
    ],
    web: 'https://www.instagram.com/debatesuam/',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'sociedaddedebates.asociacion@uam.es'
  },
  {
    video: '',
    name: 'FFIPP España, for Human Rights in Israel and Palestine',
    center: 'Interfacultativa',
    description: [
      'FFIPP es una asociación no gubernamental y sin ánimo de lucro compuesta por estudiantes internacionales que aboga por la defensa de los Derechos Humanos en Israel y Palestina.',
      'Su labor es educativa y formativa, enfocada desde el respeto de los Derechos Humanos, el objetivo es informar y sensibilizar sobre las violaciones de estos Derechos.',
      'Además, se busca estimular una reflexión y un debate sobre la situación y las posibles soluciones dentro de la comunidad académica esto se suele hacer a través de talleres de educación, charlas académicas, webinars, presentaciones de películas u otras obras artísticas que permitan dar más visibilidad y para dar entender mejor la situación que se está dando en Israel/Palestina.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'ffipp.asociacion@uam.es'
  },
  {
    video: '',
    name: 'UAM Entiende',
    center: 'Interfacultativa',
    description: [
      'La asociación se constituye como un espacio seguro en el que compartir la situación del colectivo LGTBIAQ+ en la universidad, programando actividades como:',
      ' - Jornadas de talleres y charlas.',
      ' - Programar la realización de pruebas rápidas, gratuitas y anónimas de VIH',
      ' - Concursos de fotografía, relatos, poesía',
      ' - Concentraciones de activismo contra la discriminación por razón de género y sexualidad',
      ' - Entre otras.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'uamentiende.asociacion@uam.es'
  },
  {
    video: '',
    name: 'GEONOPIA',
    center: 'Facultad de Filosofía y Letras',
    description: [
      'Geonopia tiene como fines promover el estudio integral de la geografía y la ordenación del territorio, para ello se van a desarrollar actividades como charlas, conferencias, talleres y excursiones, además se va a crear un archivo documental una página web y distintos perfiles en redes sociales donde se publique información de interés.',
      'Además, se pretende promover la integración y cooperación entre los distintos miembros del departamento de geografía y grados de la facultad.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: 'geonopia.asociacion.filosofia@uam.es'
  },
  {
    video: '',
    name: 'Corte Académica',
    center: 'Facultad de Derecho',
    description: [
      'En nuestra asociación perseguimos la divulgación y entrenamiento de conocimientos jurídicos de carácter práctico y, en especial, la argumentación jurídica y las nociones fundamentales de la teoría del derecho y de las disciplinas jurídicas representadas en el derecho positivo, así como el afianzamiento de un entorno de colaboración entre profesionales del ámbito jurídico y la comunidad universitaria.',
      'Entre nuestras principales actividades se encuentran la simulación de juicios a modo de debate académico en el ámbito de las asignaturas de formación básicas y obligatorias del Grado en Derecho, con premios para los ganadores, tribunales formados por profesores y con la asistencia de ojeadores de despachos de abogados.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: ''
  },
  {
    video: '',
    name: "ELSA (European Law's Students Association",
    center: 'Facultad de Derecho',
    description: [
      'La naturaleza de ELSA es la de una asociación estudiantil sin ánimo de lucro que busca enriquecer la experiencia educativa de los estudiantes de derecho.',
      'Los miembros de ELSA somos estudiantes motivados y comprometidos que deseamos contribuir al entorno académico y cultural de la Universidad Autónoma y, al mismo tiempo, mejorar su propia formación legal y personal.',
      'Organizamos conferencias, seminarios, talleres y competiciones que permiten a los estudiantes adquirir conocimientos prácticos y teóricos en áreas de derecho relevantes y de actualidad.',
      'Además, la asociación ofrece oportunidades de desarrollo personal y profesional a través de programas de intercambio, pasantías y eventos de networking.'
    ],
    web: '',
    join: '',
    instagram: '',
    twitter: '',
    facebook: '',
    email: ''
  }
  // {
  //     video: '',
  //     name: '',
  //     center: '',
  //     description: [
  //         '',
  //     ],
  //     web: '',
  //     join: '',
  //     instagram: '',
  //     twitter: '',
  //     facebook: '',
  //     email: '',
  // },
]
