import './DigitalEnvironment.scss'

import * as lottie_auth from '../../lotties/DigitalEnvironment/auth.json'

import React, { useEffect, useRef, useState } from 'react'
import { animated, useTransition } from 'react-spring'

import AddressBookComp from '../../components/AddressBook/AddressBook'
import AngleLeft from '../../components/Icons/AngleLeft'
import ArrowCircle from '../../components/Icons/ArrowCircle'
import ArrowsDown from '../../components/ArrowsDown/ArrowsDown'
import BackContainer from '../../components/BackContainer/BackContainer'
import ChatNur from '../../components/Icons/ChatNur'
import Desplegable from '../../components/Desplegable/Desplegable'
import DidYouKnow from '../../components/DidYouKnow/DidYouKnow'
import ExternalLink from '../../components/ExternalLink/ExternalLink'
import { ReactComponent as Facebook } from '../../icons/social/facebook.svg'
import { ReactComponent as Flickr } from '../../icons/social/flickr.svg'
import FolderNur from '../../components/Icons/FolderNur'
import { ReactComponent as Instagram } from '../../icons/social/instagram.svg'
import { ReactComponent as Linkedin } from '../../icons/social/linkedin.svg'
import ListItem from '../../components/ListItem/ListItem'
import Lottie from 'react-lottie'
import PeopleNur from '../../components/Icons/PeopleNur'
import { ReactComponent as Pinterest } from '../../icons/social/pinterest.svg'
import SocialCard from '../../components/SocialCard/SocialCard'
import { ReactComponent as X } from '../../icons/social/x.svg'
import { ReactComponent as Youtube } from '../../icons/social/youtube.svg'
import { contacts } from '../../data'
import useScreenSize from '../../hooks/screen-size'

const DigitalEnvironment = ({
  color = 'dark-blue',
  secondary = 'green',
  handleScrollParent,
  scrollRight,
  modalHandling,
  closeModal,
  section,
  parallax,
  lock,
  titleOffset
}) => {
  const { isDesktop } = useScreenSize()

  const dektopContainer = useRef(null)
  const [modalContent, setModalContent] = useState()
  const [modal, setModal] = useState()
  const [openDesktop, setOpenDesktop] = useState(false)
  const [change, setChange] = useState(false)

  const transition = useTransition(openDesktop, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  useEffect(() => {
    if (!isDesktop) {
      const sec = document.getElementById(`scroll-container-${section}`)
      const scroll = () => {
        const posX = sec.scrollLeft
        handleScrollParent(posX > 50)
      }
      sec.addEventListener('scroll', scroll)
      return () => sec.removeEventListener('scroll', scroll)
    }
  }, [isDesktop])

  useEffect(() => {
    if (openDesktop && isDesktop) {
      lock(true)
      dektopContainer.current.parentElement.scrollLeft = dektopContainer.current.offsetLeft
    } else {
      lock(false)
    }
  }, [openDesktop, isDesktop])

  const handleModal = (content) => {
    setModalContent(content)
    setChange(!change)
  }

  // Lotties

  // https://lottiefiles.com/16763-animated-mail-illustration
  const lottieAuth = (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: lottie_auth.default,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
      }}
      width={150}
      height={150}
      speed={0.5}
    />
  )

  // Desplegables area
  const digitalIdentity = (
    <div className="desplegable-container digital-identity">
      {lottieAuth}
      <ul className="kite-list">
        <li>
          <ListItem color={color} kiteColor={secondary}>
            <span className="bold">Autentificarte de manera segura</span> en todas las instituciones y organismos nacionales y europeos.
          </ListItem>
        </li>
        <li>
          <ListItem color={color} kiteColor={secondary}>
            <span className="bold">Ser dueño/a de tu tiempo</span>, porque no estarás sujeto/a a los horarios de oficina para relacionarte con las
            administraciones públicas.
          </ListItem>
        </li>
        <li>
          <ListItem color={color} kiteColor={secondary}>
            <span className="bold">Evitar las colas y esperas que actualmente</span> requieren algunos servicios, ya que podrás realizar todos los trámites por
            Internet.
          </ListItem>
        </li>
      </ul>
      <p className="text-semi-normal bold bottom-text purple">Te avisaremos tan pronto como esté disponible, ¡estate al tanto!</p>
    </div>
  )

  const teamAdvantages = (
    <div className="desplegable-container teams-advantages">
      <p className="text-normal">Lo bueno de usar una herramienta como Teams es que puedes:</p>

      <div className="icon-group">
        <div className="icon-container">
          <PeopleNur size={30} color={secondary} />
        </div>
        <p className="text-normal">
          Pertenecer a <span className="bold">varios equipos</span>
        </p>
      </div>
      <div className="icon-group">
        <div className="icon-container">
          <ChatNur width={30} color={secondary} />
        </div>
        <p className="text-normal">
          <span className="bold">Crear canales</span> sobre diferentes temas
        </p>
      </div>
      <div className="icon-group">
        <div className="icon-container">
          <FolderNur width={30} color={secondary} />
        </div>
        <p className="text-normal">
          Dentro de cada uno de estos, <span className="bold">guardar las conversaciones</span>, archivos y grabaciones de reunión en un lugar organizado.
        </p>
      </div>
    </div>
  )

  const hotToAccess = (
    <div className="desplegable-container how-to-access">
      <p className="text-normal">
        <span className="bold">Teams se encuentra incluido en el paquete Office 365</span>, al que tiene acceso todo el personal y estudiantado de la UAM, por
        lo que solo tienes que <span className="bold">iniciar sesión online con tu cuenta de estudiante</span> para utilizarlo en su versión de navegador o{' '}
        <span className="bold">descargar la app oficial</span>.
      </p>
      <div className="logos-container">
        <div className="img-container">
          <img src="/svg/icons/teams.png" alt="" />
        </div>
        <div className="right">
          <div className="img-container">
            <a href="https://play.google.com/store/apps/details?id=com.microsoft.teams" target="_blank" rel="noopener noreferrer">
              <img src="/svg/logos/google-play.svg" alt="" />
            </a>
          </div>
          <div className="img-container apple">
            <a href="https://apps.apple.com/us/app/microsoft-teams/id1113153706" target="_blank" rel="noopener noreferrer">
              <img src="/svg/logos/apple-store.png" alt="" />
            </a>
          </div>
        </div>
      </div>
      <p className="text-normal">
        En el caso de que el profesor o profesora de una asignatura utilice Teams, podréis chatear ante cualquier duda o necesidad.&nbsp;
        <span className="bold">Puedes acceder a Microsoft Teams con las credenciales de la UAM (cuenta de correo y contraseña).</span>
      </p>
    </div>
  )

  const socialUAM = (
    <>
      <SocialCard color={secondary} title="La UAM">
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://es-es.facebook.com/universidadautonomademadrid" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Instagram style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.instagram.com/uammadrid/" target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Linkedin style={{ fill: '#84BF41' }} />
          </i>
          <a
            href="https://www.linkedin.com/company/universidad-autonoma-de-madrid?trk=vsrp_companies_res_name&trkInfo=VSRPsearchId%3A904739271417556526931%2CVSRPtargetId%3A166613%2CVSRPcmpt%3Aprimary"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn (Empresa)
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Linkedin style={{ fill: '#84BF41' }} />
          </i>
          <a
            href="https://www.linkedin.com/edu/school?id=12194&trk=tyah&trkInfo=tarId%3A1416344610643%2Ctas%3Auniversidad%20aut%C3%B3noma%20de%20madrid%2Cidx%3A4-1-6"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn (Estudiantes)
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/#!/uam_madrid" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/UAM" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Campus de excelencia internacional">
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/UamCsic" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/playlist?list=PL11E31D516AB1CDCF" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="AlumniUAM">
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/alumniUAM" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Instagram style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.instagram.com/alumni_uam/" target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Linkedin style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.linkedin.com/in/alumniuam" target="_blank" rel="noopener noreferrer">
            LinkedIn
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/AlumniUAM" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/user/AlumniUAM" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Fundación UAM (FUAM)">
        <div className="social-item">
          <i className="social-icon">
            <Linkedin style={{ fill: '#84BF41' }} />
          </i>
          <a
            href="https://www.linkedin.com/company/2990979?trk=tyah&trkInfo=tarId%3A1416476624320%2Ctas%3Afundacion%20universidad%20auto%2Cidx%3A1-1-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/fuam_uam" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
      </SocialCard>
    </>
  )

  const socialOffices = (
    <>
      <SocialCard color={secondary} title="Biblioteca y Archivo">
        <div className="social-item">
          <i className="social-icon">
            <Flickr style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.flickr.com/photos/uam_biblioteca" target="_blank" rel="noopener noreferrer">
            Flickr
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Instagram style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://instagram.com/uam_biblioteca/" target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Pinterest style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.pinterest.com/UAM_Biblioteca/" target="_blank" rel="noopener noreferrer">
            Pinterest
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/uam.biblioteca/" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/UAM_Biblioteca" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/playlist?list=PL7D9B85F1C9708824" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Centro Cultural La Corrala / Museo Artes y Tradiciones Populares">
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/MuseoATP" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/channel/UCs6AMiL3x7R24-NoUDqOqbA" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Centro Superior de Investigación y Promoción de la Música">
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/CSIPM_UAM" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Centro de Iniciativas Emprendedoras (CIADE)">
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/CIADE_UAM" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/user/CIADEUAM" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Colegio Mayor “Juan Luis Vives” ">
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/cmluisvives" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/channel/UCYMm8v2huLxGvnbdikox6Og" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="EcoCampus">
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/ecocampusUAM" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Ediciones UAM">
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/UAM_EDICIONES" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/user/betocinio" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Empleabilidad (Oficina de Prácticas Externas y Orientación para el Empleo)">
        <div className="social-item">
          <i className="social-icon">
            <Linkedin style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.linkedin.com/company/166613?trk=edu-cp-sa-cname" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/OPEUAM" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Oficina de Acción solidaria y cooperación">
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/ProgramaUAM" target="_blank" rel="noopener noreferrer">
            Discapacidad
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/UAM_Solidaria" target="_blank" rel="noopener noreferrer">
            Voluntariado
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Oficina de acogida">
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/UAM_acogida" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Oficina de Actividades Culturales">
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/OACUAM" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Instagram style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.instagram.com/oac_uam/" target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/UAM_OAC" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Oficina de orientación y atención al estudiante">
        <div className="social-item">
          <i className="social-icon">
            <Instagram style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://instagram.com/oae_uam/" target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/OAE_UAM" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Oficina de Proyectos Europeos e Internacionales">
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/uamopei/" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Instagram style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.instagram.com/uam_opei/" target="_blank" rel="noopener noreferrer">
            Instagram{' '}
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Linkedin style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://es.linkedin.com/in/uamopei" target="_blank" rel="noopener noreferrer">
            LinkedIn
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/UAM_OPEI" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/channel/UC8grlH1UyxCyiYWev8rzu7g" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Servicio de Educación Física y Deporte">
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a
            href="https://www.facebook.com/pages/Servicio-de-Educaci%C3%B3n-F%C3%ADsica-y-Deporte-UAM/723774024367192?fref=ts"
            target="_blank"
            rel="noopener noreferrer"
          >
            X.com
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Instagram style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.instagram.com/uam_deportes/" target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/SEFyDUAM" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Servicio de Idiomas">
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/pages/Servicio-de-Idiomas-UAM/1379785065677579?fref=ts" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Unidad de Cultura Científica">
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/UAM_Gazette" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Unidad de Igualdad de la UAM">
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/UAM_UIgualdadG" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/unidaddeigualdadUAM/" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Instagram style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.instagram.com/uig_uam/" target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/user/unidaddeigualdadUAM" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </div>
      </SocialCard>
    </>
  )

  const socialCenters = (
    <>
      <SocialCard color={secondary} title="Facultad de Ciencias">
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/FacultadCienciasUAM" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/CienciasUAM?lang=es" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Facultad de Ciencias Económicas y Empresariales">
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/facultadcienciaseconomicasuam" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/orieconomicasuam" target="_blank" rel="noopener noreferrer">
            Facebook Oficina de Relaciones Internacionales
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Instagram style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.instagram.com/uam_economicas/" target="_blank" rel="noopener noreferrer">
            Instragram
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/UAM_Economicas" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Facultad de Derecho">
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/facultad.derecho.uam?fref=nf" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/derecho_uam" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/channel/UCmmQSFZPARiKEH8Q9I4fItQ" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Facultad de Filosofía y Letras">
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/FyLUAM" target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/FyL_UAM" target="_blank" rel="noopener noreferrer">
            X.com
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Facultad de Formación de Profesorado y Educación">
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/channel/UCrjvYexd9U-zHWnLvQ10v2A" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Facultad de Medicina">
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/aieme.uam?fref=ts" target="_blank" rel="noopener noreferrer">
            Facebook Asociación Internacional Estudiantes Medicina
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/postgrado.biociencias.moleculares" target="_blank" rel="noopener noreferrer">
            Facebook Posgrado en Biociencias Moleculares
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/trast_afectivos" target="_blank" rel="noopener noreferrer">
            Grupo de investigación (Dpto. Psiquiatría)
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/UAM_medicina" target="_blank" rel="noopener noreferrer">
            Medicina
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/UAM_Biosciences" target="_blank" rel="noopener noreferrer">
            Posgrado en Biociencias Moleculares
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <X style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://x.com/PosgradoFarma" target="_blank" rel="noopener noreferrer">
            Posgrado en Farmacología/Fisiología
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Facultad de Psicología">
        <div className="social-item">
          <i className="social-icon">
            <Facebook style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.facebook.com/ORI-Psicolog%C3%ADa-UAM-899741173421899/" target="_blank" rel="noopener noreferrer">
            Facebook de la Oficina de Relaciones Internacionales
          </a>
        </div>
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/channel/UC-cLdB-fwGsQkMhLdZs3n_g" target="_blank" rel="noopener noreferrer">
            Youtube
          </a>
        </div>
      </SocialCard>

      <SocialCard color={secondary} title="Escuela Politécnica Superior">
        <div className="social-item">
          <i className="social-icon">
            <Youtube style={{ fill: '#84BF41' }} />
          </i>
          <a href="https://www.youtube.com/user/EPSUAMTeam" target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        </div>
      </SocialCard>
    </>
  )

  // Modals area
  const wifi = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Conéctate a la red WiFi</h3>
          <div className="actual-text">
            <p className="text-normal">
              Hay muchas cosas de tu vida en la UAM que necesitarás <span className="bold">gestionar online</span> y otras tantas para las que la Autónoma te
              ofrece recursos digitales que te facilitarán mucho la tarea. Es importante que te familiarices con estos entornos y herramientas para resolver de
              manera más eficiente las necesidades que vayan surgiendo.
            </p>

            <div className="wifi-image">
              <img src="/svg/front-pages/digital-environment/stay-connected.svg" alt="" />
            </div>

            <p className="text-normal">
              La UAM te proporciona <span className="bold">acceso gratuito a la red wifi EDUROAM</span>, a través de la que podrás conectarte a internet
              libremente, no solo en las instalaciones de la UAM, sino también en los campus de otras universidades españolas y extranjeras. Sigue las
              instrucciones que encontrarás en el siguiente enlace para conectar tus dispositivos:
            </p>

            <ExternalLink color={secondary} secondary={color} url="https://www.uam.es/uam/wifi" text="Pincha en la pestaña 'Documentación'" />
          </div>
          {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
        </div>
      </div>
    </div>
  )

  const moodle = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Entra en el Moodle</h3>
          <div className="actual-text">
            <p className="text-normal">
              La plataforma Moodle UAM <span className="bold">(moodle.uam.es)</span> es un <span className="bold">campus virtual</span> de apoyo a la docencia
              en el que tus profesoras y profesores colgarán materiales, abrirán espacios de entrega de tareas o subirán las notas de trabajos y exámenes. Usa
              el ID-UAM para acceder.
            </p>
            <ExternalLink url="https://moodle.uam.es/" color={secondary} secondary={color} />
            <ListItem color={color} kiteColor={secondary}>
              ¿Problemas para acceder?
              <span className="bold">
                <a href="https://moodle.uam.es/mod/page/view.php?id=1775071"> ¡Consulta esta guía!</a>
              </span>{' '}
            </ListItem>
            <DidYouKnow color={color}>
              <p className="text-normal">No podrás entrar hasta que hayan pasado 24/48 horas de la activación de tu cuenta de correo.</p>
            </DidYouKnow>
          </div>
        </div>
        {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
      </div>
    </div>
  )

  const sigma = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Añade Sigma a tus favoritos</h3>
          <div className="actual-text">
            <p className="text-normal">
              SIGMA es la <span className="bold">secretaría virtual de la UAM</span>, la plataforma de gestión académica en la que hiciste tu matrícula. En ella
              podrás <span className="bold">consultar y tramitar</span> todo lo relativo a tu <span className="bold">expediente académico</span> (tus
              calificaciones, la nota media o los créditos superados), así que <span className="bold">¡no la pierdas de vista!</span>
            </p>

            <div className="sigma-container">
              <img src="/svg/front-pages/digital-environment/sigma.svg" alt="" />
            </div>

            <AddressBookComp color={color} data={contacts['secretaria-virtual']} />
          </div>
        </div>
        {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
      </div>
    </div>
  )

  const electronicHost = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heoric italic uppercase">Sede electrónica</h3>
          <div className="actual-text">
            <p className="text-normal">
              Además de <span className="bold">SIGMA</span>, la Universidad también dispone de una <span className="bold">sede administrativa electrónica</span>{' '}
              donde puedes encontrar información sobre el resto de trámites relevantes y realizar solicitudes por vía telemática.
            </p>
            <p className="text-normal">
              La Sede Electrónica es el lugar dónde podrás solicitar permanencia o convocatoria excepcional, dirigirte a la Defensoría Universitaria o visitar
              su Tablón para consultar resoluciones o convocatorias, entre otros <span className="bold">trámites</span> de los que te daremos más información en
              esta guía.
            </p>
            <p className="text-normal">
              Para algunas gestiones en la Sede Electrónica necesitarás disponer de un{' '}
              <span className="bold">certificado o firma digital que posibilite acreditar tu identidad</span> de manera segura en plataformas virtuales.
            </p>
            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Nueva identidad digital propia de la UAM">
                {digitalIdentity}
              </Desplegable>
            </div>
          </div>
        </div>
        {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
      </div>
    </div>
  )

  const freeSoftware = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Software gratuito</h3>
          <div className="actual-text">
            <p className="text-normal">
              Los estudiantes de la UAM pueden acceder a la descarga gratuita del paquete de aplicaciones <span className="bold">Microsoft Office 365</span>,
              que incluye Word, PowerPoint, Excel y otras herramientas muy útiles.
            </p>
            <div className="img-container monitor">
              <img src="/svg/icons/monitor-office.svg" alt="" />
            </div>
            <p className={`text-semi-normal bold colored ${secondary}`}>¡Aprovecha y sácale partido!</p>
            <p className="text-normal">Te resultará mucho más fácil avanzar en cualquier proyecto.</p>
            <ExternalLink
              text="Accede para saber cómo descargar y configurar las aplicaciones."
              url="https://faq.uam.es/index.php?action=artikel&cat=12&id=14&artlang=es"
              color={secondary}
              secondary={color}
            />
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const onlineTeaching = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Docencia Online en Teams</h3>
          <div className="actual-text">
            <p className="text-normal">
              El <span className="bold">entorno de trabajo online</span> que utilizamos en la <span className="bold">UAM</span> incluye, además de Moodle, otras
              herramientas como <span className="bold">Teams</span>, que permiten a tus profesores y profesoras llevar a cabo clases a distancia, tutorías o
              diferentes tareas si lo consideran necesario. También puedes utilizarlas para realizar{' '}
              <span className="bold">videoconferencias y compartir documentos</span> con grupos de compañeros o compañeras y realizar de manera colaborativa los
              trabajos en equipo que el profesorado os pide.
            </p>
            <div className="img-container online-teaching-image">
              <img src="/svg/icons/online-teaching.svg" alt="" />
            </div>
            <DidYouKnow color={color}>
              <p className="text-normal">
                Con el objetivo de facilitar el seguimiento adecuado de la docencia para estudiantes que puedan estar en una situación económica desfavorable,
                la Universidad Autónoma dispone de un programa de <span className="bold">préstamo de material informático</span> como ordenadores portátiles o
                cámaras web. Esta convocatoria está abierta durante gran parte del año académico, consúltala en la web si en algún momento te encuentras en una
                situación que lo requiera.
              </p>
            </DidYouKnow>
            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Ventajas de Teams">
                {teamAdvantages}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Cómo acceder">
                {hotToAccess}
              </Desplegable>
              <AddressBookComp color={color} data={contacts['docencia-digital-uam']} />
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const vpnAcccess = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Accede mediante VPN (Virtual Private Network)</h3>
          <div className="actual-text">
            <p className="text-normal">
              Algunas <span className="bold">aplicaciones y servicios de red</span> están sólo disponibles para los miembros de la{' '}
              <span className="bold">comunidad Universitaria</span> (antivirus institucional, acceso a Bases de Datos y Revistas del Servicio de Bibliotecas,
              etc...). El acceso a estos recursos sólo se permite desde la <span className="bold">red de la UAM</span>, pero no desde cualquier otra parte de
              Internet (la red de otra Universidad, tu domicilio, etc.). Si en algún momento necesitas acceder a algunos de los recursos restringidos fuera del
              campus, puedes instalar la{' '}
              <a href="https://vpn.uam.es/" target="_blank" rel="noopener noreferrer" className="bold">
                VPN (vpn.uam.es)
              </a>{' '}
              y <span className="bold">autenticarte con el ID-UAM</span> para conectarte como si estuvieses en la Autónoma.
            </p>
            <p className="text-normal">
              Para este tipo de acceso, será necesario que hayas configurado previamente el <span className="bold">Múltiple Factor de Autenticación (MFA)</span>{' '}
              para garantizar que el acceso no se produce de una manera fraudulenta.
            </p>
            <ExternalLink
              text="Aprende cómo configurar el MFA UAM"
              url="https://www.uam.es/uam/tecnologias-informacion/servicios/multiple-factor-autenticacion#:~:text=Por%20ello%20la%20UAM%20ha,titular%20de%20la%20cuenta%20corporativa."
              color={secondary}
              secondary={color}
            />
            <div className="img-container vpn">
              <img src="/svg/icons/vpn.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const uamApp = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <div className="actual-text">
            <h3 className="text-medium heroic italic uppercase">Descubre la app de la UAM</h3>
            <p className="text-normal">
              Disponible para <span className="bold">iOS y Android</span>, podrás conocer todas las <span className="bold">novedades de la Universidad</span> o
              tener un <span className="bold">carné digital</span> siempre a mano para la biblio, además de consultar tus <span className="bold">notas</span>,
              la agenda semanal de <span className="bold">actividades</span>, la <span className="bold">oferta académica, becas y ayudas</span> al estudio, los
              menús de las cafeterías y las direcciones de contacto de los principales servicios de la Universidad. Descarga la app y lleva siempre toda la
              información en el bolsillo.
            </p>
            <div className="logos-container-apps">
              <div className="img-container">
                <img src="/svg/logos/logo-square-uam.svg" alt="" />
              </div>
              <div className="right-logos">
                <div className="img-container">
                  <a href="https://play.google.com/store/apps/details?id=net.universia.uam">
                    <img src="/svg/logos/google-play.svg" alt="" />
                  </a>
                </div>
                <div className="img-container">
                  <a href="https://apps.apple.com/es/app/universidad-aut%C3%B3noma-de-madrid/id1272096838">
                    <img src="/svg/logos/app-store.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  const social = (
    <div className={`modern-modal-container ${color}`}>
      <div className="inner-container">
        <div className="text-container">
          <h3 className="text-medium heroic italic uppercase">Encuentranos en nuestras redes sociales</h3>
          <div className="actual-text">
            <div className="questions-container-list">
              <Desplegable secondary={secondary} color={color} title="Redes sociales UAM">
                {socialUAM}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Oficinas y Servicios Centrales">
                {socialOffices}
              </Desplegable>
              <Desplegable secondary={secondary} color={color} title="Centros (Facultades y EPS)">
                {socialCenters}
              </Desplegable>
            </div>
          </div>
        </div>
      </div>
      {!isDesktop && <BackContainer secondary={color} color={secondary} goBack={() => closeModal()} />}
    </div>
  )

  useEffect(() => {
    if (modalContent === 'moodle') isDesktop ? setModal(moodle) : modalHandling(moodle)
    else if (modalContent === 'wifi') isDesktop ? setModal(wifi) : modalHandling(wifi)
    else if (modalContent === 'sigma') isDesktop ? setModal(sigma) : modalHandling(sigma)
    else if (modalContent === 'electronic-host') isDesktop ? setModal(electronicHost) : modalHandling(electronicHost)
    else if (modalContent === 'free-software') isDesktop ? setModal(freeSoftware) : modalHandling(freeSoftware)
    else if (modalContent === 'online-teaching') isDesktop ? setModal(onlineTeaching) : modalHandling(onlineTeaching)
    else if (modalContent === 'vpn-access') isDesktop ? setModal(vpnAcccess) : modalHandling(vpnAcccess)
    else if (modalContent === 'uam-app') isDesktop ? setModal(uamApp) : modalHandling(uamApp)
    else if (modalContent === 'social') isDesktop ? setModal(social) : modalHandling(social)
    else isDesktop && setModalContent('wifi')
  }, [modalContent, change])

  const Splash = (
    <>
      <div className="logo-back">
        <img src="/splash/svg/DigitalEnvironment_logo_back.svg" alt="" />
      </div>
      <animated.div className="logo-main" style={{ transform: parallax.logo_main(section) }}>
        <img src="/splash/svg/DigitalEnvironment_logo_main.svg" className="illustration" alt="" />
        <img src="/splash/png/DigitalEnvironment.png" className="image" alt="" />
      </animated.div>
      <div className="logo-front">
        <img src="/splash/svg/DigitalEnvironment_logo_front.svg" alt="" />
      </div>
    </>
  )

  return (
    <>
      {isDesktop ? (
        <div ref={dektopContainer} className={`main-page-desktop DigitalEnvironment ${color} ${openDesktop ? 'open' : 'closed'}`}>
          <div className="arrow-circle-container" onClick={() => setOpenDesktop(false)}>
            <ArrowCircle color={color} />
          </div>

          <div className="inner-block">
            <div className="left side">
              <h1 className="urbana uppercase">Configura tu entorno digital</h1>

              {transition.map(
                ({ item, props }, i) =>
                  item && (
                    <animated.div key={`section-first-steps-index-${i}`} className="left-side-desktop" style={props}>
                      <div className="index-container-desktop">
                        <div className="inner-container">
                          <ul className="index-list">
                            <li className={`uppercase bold text-normal ${modalContent === 'wifi' ? 'selected' : ''}`} onClick={() => setModalContent('wifi')}>
                              1. Conéctate a la red wifi{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'moodle' ? 'selected' : ''}`}
                              onClick={() => setModalContent('moodle')}
                            >
                              2. Entra en Moodle{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li className={`uppercase bold text-normal ${modalContent === 'sigma' ? 'selected' : ''}`} onClick={() => setModalContent('sigma')}>
                              3. Añade Sigma a tus favoritos{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'electronic-host' ? 'selected' : ''}`}
                              onClick={() => setModalContent('electronic-host')}
                            >
                              4. Sede electrónica{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'free-software' ? 'selected' : ''}`}
                              onClick={() => setModalContent('free-software')}
                            >
                              5. Software gratuito{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'online-teaching' ? 'selected' : ''}`}
                              onClick={() => setModalContent('online-teaching')}
                            >
                              6. Docencia online en Teams{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'vpn-access' ? 'selected' : ''}`}
                              onClick={() => setModalContent('vpn-access')}
                            >
                              7. Accede mediante VPN{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'uam-app' ? 'selected' : ''}`}
                              onClick={() => setModalContent('uam-app')}
                            >
                              8. Descubre la app de la UAM{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                            <li
                              className={`uppercase bold text-normal ${modalContent === 'social' ? 'selected' : ''}`}
                              onClick={() => setModalContent('social')}
                            >
                              9. Redes sociales UAM{' '}
                              <div className="arrows-container">
                                <ArrowsDown color={secondary} />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={`back-square ${color}`} />
                      </div>
                    </animated.div>
                  )
              )}

              <div className="front-logo-container-desktop" id="digital-environment-logo-desktop">
                {Splash}
              </div>
            </div>
            <div className="right-container">
              <div className="top spacer" />
              <div className="right side" onClick={() => setOpenDesktop(true)}>
                <div className="content">{modal}</div>
              </div>
              <div className="bottom spacer" />
            </div>
          </div>
        </div>
      ) : (
        <div className={`DigitalEnvironment main-page ${color}`} id="digital-environment">
          <div className="inner-page" id={`scroll-container-${section}`}>
            <div className="left side">
              <div className="header">
                <div className="logo-container">
                  <img src={`/svg/logo-uam/logo-uam-estudiantes-${color}.svg`} alt="" />
                </div>
              </div>

              <div className="front-logo-container" id="digital-environment">
                {Splash}
              </div>

              <div className="footer" onClick={() => scrollRight(false)} style={{ bottom: titleOffset }}>
                <div className="text-block">
                  <h2 className={`${color}`}>
                    <span className={`urbana uppercase text-title`}>Configura tu entorno digital</span>
                  </h2>
                </div>
                <div className="arrows-container">
                  <ArrowsDown color={color} />
                </div>
              </div>
            </div>
            <div className="right side" id="digital-environment-right">
              <div className={`index-container ${color}`}>
                <div className="title-container-inside">
                  <h2 className={`urbana uppercase text-title ${color}`}>Configura tu entorno digital</h2>
                </div>
                <div className="inner-container">
                  <ul className="index-list">
                    <li className="uppercase bold text-normal" onClick={() => handleModal('wifi')}>
                      <span>1. Conéctate a la red wifi</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('moodle')}>
                      <span>2. Entra en Moodle</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('sigma')}>
                      <span>3. Añade Sigma a tus favoritos</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('electronic-host')}>
                      <span>4. Sede electrónica</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('free-software')}>
                      <span>5. Software gratuito</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('online-teaching')}>
                      <span>6. Docencia online en Teams</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('vpn-access')}>
                      <span>7. Accede mediante VPN</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('uam-app')}>
                      <span>8. Descubre la app de la UAM</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                    <li className="uppercase bold text-normal" onClick={() => handleModal('social')}>
                      <span>9. Redes sociales UAM</span>
                      <div className="arrows-container">
                        <ArrowsDown color={secondary} />
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={`back-square ${color}`} />
              </div>

              <div className="bottom-container">
                <div className="go-back" onClick={() => scrollRight(true)}>
                  <div className="back-icon-container">
                    <AngleLeft color={secondary} />
                  </div>
                  <span className="text-normal">Volver</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DigitalEnvironment
