import React from 'react'
import { colorToHex } from '../../data'

const Computer = ({ color = 'red', width = 41 }) => {
  return (
    <svg width={width} height={width * (width / 29)} viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.8333 25C35.6667 25 37.15 23.5 37.15 21.6667L37.1667 3.33333C37.1667 1.5 35.6667 0 33.8333 0H7.16667C5.33333 0 3.83333 1.5 3.83333 3.33333V21.6667C3.83333 23.5 5.33333 25 7.16667 25H0.5C0.5 26.8333 2 28.3333 3.83333 28.3333H37.1667C39 28.3333 40.5 26.8333 40.5 25H33.8333ZM7.16667 3.33333H33.8333V21.6667H7.16667V3.33333ZM20.5 26.6667C19.5833 26.6667 18.8333 25.9167 18.8333 25C18.8333 24.0833 19.5833 23.3333 20.5 23.3333C21.4167 23.3333 22.1667 24.0833 22.1667 25C22.1667 25.9167 21.4167 26.6667 20.5 26.6667Z"
        fill={colorToHex(color)}
      />
    </svg>
  )
}

export default Computer
